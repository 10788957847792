<template>
    <div class="pay-wrapper">
      <p>请用支付宝/微信扫一扫</p>
      <img
        width="300"
        height="300"
        :src="img"
        :style="{ display: 'block', marginBottom: '20px' }"
      />
      <el-button @click="paySuccess" type="primary">支付成功</el-button>
      <div class="foot"></div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  
  import http from './http'
  import api from './api'
  
  export default {
    data() {
      return {
        img: "",
      }
    },
    created() {
  
    },
    mounted() {
      this.initData()
    },
    methods: {
      async paySuccess() {
        const orderNo = window.location.hash.split('=')[1];
        try {
          const response = await http.get(api.pay, { orderNo });
          if (response.status === 10000) {
            this.$router.push('/order_detail?orderNo=' + orderNo);
          } else {
            this.$message({
              type: 'error',
              message: response.msg
            });
          }
        } catch (error) {
          this.$message({
            type: 'error',
            message: '支付失败，请重试'
          });
        }
      },
      async initData() {
        try {
          const response = await http.get(api.orderCode, { orderNo: window.location.hash.split('=')[1] });
          if (response.status === 10000) {
            this.img = response.data;
          } else {
            this.$message({
              type: 'error',
              message: response.msg
            });
          }
        } catch (error) {
          this.$message({
            type: 'error',
            message: '初始化数据失败，请重试'
          });
        }
      },
    },
  }
  </script>
  
  <style lang="less" scoped>
  .pay-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  </style>
  