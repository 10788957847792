export default {
  goodList: "/category-product/product/list",

  categoryList: "/category-product/category/list",

  login: "/user/login",
  getToken:'/user/loginWithJWT',
  logout: "/user/user/logout",
  register: "/user/register",
  editPersonal: "/user/user/update",
  productList: "/category-product/product/list",
  productDetail: "/category-product/product/detail",
  cartAdd: "/cart-order/cart/add",
  cartList: "/cart-order/cart/list",
  cartUpdate: "/cart-order/cart/update",
  cartDelete: "/cart-order/cart/delete",
  orderCreate: "/cart-order/order/create",
  orderDetail: "/cart-order/order/detail",
  orderCancel: "/cart-order/order/cancel",
  orderFinish: "/cart-order/order/finish",
  orderCode: "/cart-order/order/qrcode",
  pay: "/cart-order/pay",
  orderList: "/cart-order/order/list",

  cartSelect: "/cart-order/cart/select",
  cartSelectAll: "/cart-order/cart/selectAll",
};
